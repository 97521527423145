import { useState, useEffect } from 'react';

/** Hook for loading a resource.
 * Loads a resource as an effect whenever the dependencies change.
 * 
 * @param {Function} resourceFn - async function returning the resource
 * @param {Array} dependencies - List of dependencies. The function is called whenever an item changes in this list.
 * @param {Object} initialValue - Initial resource value
 * 
 * @returns {Object} the requested resource data.
 * @returns {Boolean} flag indicating that the resource is loading.
 * @returns {Boolean} flag indicating that an error ocurred while loading the resource.
 * 
 */
function useResourceLoader(resourceFn, dependencies, initialValue){
    const [resource, setResource] = useState(initialValue);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setLoading(true);
        Promise.resolve().then(
            resourceFn
        ).then((newResource) => {
            if(newResource !== useResourceLoader.DoNotSet && newResource !== resource) {
                setResource(newResource);
            }
            setLoading();
        }).catch(err => {
            setLoading();
            setError(err);
        });
    }, dependencies);

    return [resource, loading, error];
}

export const DO_NOT_SET = {}

useResourceLoader.DoNotSet = DO_NOT_SET;

export default useResourceLoader;
