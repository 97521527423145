import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { AuthProvider, getToken } from './context/AuthContext';

import fetchApi from './utils/fetchApi';
import serviceMap from './servicemap.json';

import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login'));
const SelectAccount = React.lazy(() => import('./views/SelectAccount'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ForgotPassword/ResetPassword'));


const helpers = {
    auth: (url, options, fetchApi) => fetchApi(url, {
        auth: { type: 'Bearer', token: getToken() },
        ...options
    }, fetchApi)
};

helpers.api = (url, options, fetchApi) => helpers.auth(`api/${url}`, { ...options, json: true }, fetchApi);

fetchApi.configure({
    verbs: ['get', 'post', 'delete'],
    services: serviceMap,
    helpers
});


class App extends Component {
    render() {
        return (
            <HashRouter>
                <React.Suspense fallback={loading()}>
                    <AuthProvider>
                        <Switch>
                            <Route path='/login' render={props => <Login {...props} />} />
                            <Route path='/select-account' render={props => <SelectAccount {...props} />} />
                            <Route
                                path='/forgot-password'
                                render={props => <ForgotPassword {...props} />}
                            />
                            <Route
                                path='/reset-password'
                                render={props => <ResetPassword {...props} />}
                            />
                            <ProtectedRoute path='/' name='Home' component={DefaultLayout} />
                        </Switch>
                    </AuthProvider>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
