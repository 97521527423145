import queryString from 'query-string';


/** Fetch enchancing function for calling apis.
 * @param {String} url - url to get resource.
 * @param {Object} options - options object used in fetch.
 * @param {Object} options.json - options object used in fetch.
 * @param {Object} options.query - query parameters to append to url. 
 * @returns {Promise} resolved returned by fetch().
 */
function fetchApi(url, options) {
    options = options || {};
    options.headers = options.headers || {};

    if (options.return_url) {
        return url;
    }

    if (options.data) {
        options.body = JSON.stringify(options.data);
        options.headers['Content-Type'] = 'application/json';
        delete options.data;
    }

    if (options.auth) {
        options.headers.Authorization = `${options.auth.type} ${options.auth.token}`;
        delete options.auth;
    }

    if (options.query) {
        const prefix = url.indexOf('?') === -1 ? '?' : '&';
        url = url + prefix + queryString.stringify(options.query);
        delete options.query;
    }

    const json = options.json;
    delete options.json;

    return fetch(url, options).then(response => {
        if (json) {
            return response.json();
        }

        return response;
    });
}


fetchApi.configure = function fetchApi_configure({
    verbs=['get', 'post', 'delete'],
    services,
    helpers
}){
    Object.entries(services).forEach(([serviceName, serviceUrl]) => {
        if (serviceUrl && serviceUrl[serviceUrl.length-1] !== '/') {
            serviceUrl += '/';
        }
        const fetchServiceApi = serviceName ? (url, options) => fetchApi(`${serviceUrl}${url}`, options) : fetchApi;
        if (serviceName) {
            fetchApi[serviceName] = fetchServiceApi;
        }

        [[], ...Object.entries(helpers)].forEach(([helperName, helperFn]) => {
            const helper = helperFn ? (url, options) => helperFn(url, options, fetchServiceApi) : fetchServiceApi;
            if (helperName) {
                fetchServiceApi[helperName] = helper;
            }

            helper.url = (url) => helper(url, {return_url: true});

            verbs.forEach((verb) => {
                helper[verb] = (url, options) => helper(url, { method: verb, ...options });
            });
        });

    });

    fetchApi.configured = true;
    return fetchApi;
}



export default fetchApi;
